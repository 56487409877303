@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
.topBar {
    background-color: #115C23 !important;
}
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
}
.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.header {
    background-color: #4CAF50;
    color: white;
    padding: 15px;
}
.body {
    flex: 1;
    /* This ensures the body takes up the available space */
    padding: 20px;
}
.footer {
    background-color: #f0f0f0;
    color: #000;
    padding: 15px;
    margin-top: auto;
    text-align: center;
    /* This pushes the footer to the bottom of the container */
}
.app_logo {
    width: 180px;
    height: 50px;
}
.toolbar {
    padding: 0px 10px !important;
}
.screen_img {
    width: 250px;
    height: 420px;
}
.grid_content{
    text-align: center;
    position: 'fixed';
}
.grid_title{
    background-color: #f0f0f0;
    padding: 5px !important;
    color: #115C23 !important;
    font-weight: bold !important;
}
.custom-dialog {
    /* position: absolute !important;
    top: 0 !important;
    right: 0 !important; */
    /* width: 100%; */
}
.certificates{
    border: 3px solid #115C23 !important;
    margin: 10px;
}
.certificate_title2{
    color: #115C23;
    font-weight: bold !important;
}
.card {
    margin-bottom: 16px;
    background-color: #fff;
    border: 1px solid #eceff5;
    box-shadow: none;
  }
  
  .card-body {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }
  .card-title {
    text-transform: capitalize;
    letter-spacing: .02em;
    font-size: 14px !important;
    font-weight: 500;
    margin: 0;
    color: #303e67;
  }
  
  .card-header {
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #eceff5;
    border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
    letter-spacing: 0.2em;
    text-transform: capitalize;
  }
  .active-link {
    border-bottom: 2px solid #fff !important;
    
  }
  .ti-users {
    width: 40px;
  }